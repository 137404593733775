import portfolioStyle from './portfolio.module.css'
import transactionStyle from './transactions.module.css'

import { getLayout } from '../components/sg/Layout'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import Updown from '../components/Updown'
import { useState, useEffect, useRef } from 'react'
import Transaction from '../components/Transaction'
import Link from 'next/link'
import { useLiveHoldings, useHoldingsHistory } from '../hooks/useHoldings'
import useAuth from '../hooks/useAuth'
import renderChart, { drawData, defaultChartScale } from '../helpers/renderChart'
import resolveProductUnit, { defaultProductUnit } from '../helpers/resolveProductUnit'
import useLocalStorage from 'use-local-storage-state'
// import useLocalStorage, { createLocalStorageStateHook } from 'use-local-storage-state'
import NumberCount from '../components/NumberCount'
import Loading from '../components/Loading'
import Router from 'next/router'
import Cards from '../components/sg/Cards'
import Footer from '../components/sg/Footer'
import { useTransactions } from '../hooks/useTransactions'
import PullToRefresh from '../components/PullToRefresh'
import useUser from '../hooks/useUser'

let chart
let series
let extraseries
// const useProductUnitState = createLocalStorageStateHook('productUnit', defaultProductUnit)

export default function Portfolio () {
  const chartElem = useRef()
  const [t, i18n] = useTranslation('main')
  const locale = i18n.language
  const c = classNames.bind(portfolioStyle)
  const ct = classNames.bind(transactionStyle)

  const productUnit = 'gram'
  // const [productUnit, setProductUnit] = useProductUnitState()
  const [chartScale, setChartScale] = useLocalStorage('chartScale', defaultChartScale)
  const [chartSeries, setChartSeries] = useLocalStorage('chartSeries', { Cash: true, CGT: true })
  const [percentPriceDiff, setPercentPriceDiff] = useState(true)
  const [goldInfoVisible, setGoldInfoVisible] = useState()

  const { time, currency: holdingCurrency, holdings: { CGT: { Amount: productHolding, Bonus: bonusHolding, Valuation: productValue, WeightedCost: productWeightedCost, AccumulatedStorage: unbilledStorage } }, balances: { Cash: { Amount: cashBalance } }, isLoading: isPriceLoading, updateHoldings } = useLiveHoldings()
  const { productUnitShortLabel, productPriceResolution, productUnitFractionDigits } = resolveProductUnit(productUnit, 1)
  const { holdings: { CGT: prices }, balances: { Cash: balances }, isLoading: pricesLoading } = useHoldingsHistory(chartScale)
  const productAmount = productHolding / productPriceResolution

  const { authToken, authedFetch } = useAuth()
  const { user, account } = useUser()
  const createdDate = new Date(account?.CreateDate)
  const nowDate = new Date()

  const currency = holdingCurrency || account?.PreferredCurrency
  const { transactions, isLoading: isTransactionsLoading, isError: isTransactionsError, updateTransactions } = useTransactions(currency, 5)

  const accountValue = productValue + Math.max(cashBalance, 0)

  const cashCalc = Math.max(cashBalance, 0.00001) / Math.max(accountValue, 0.00001) * 100
  const cashPercent = isNaN(cashCalc) ? 100 : cashCalc

  useEffect(() => {
    if (chart && (chartSeries.Cash || chartSeries.CGT) && prices?.length && time > prices[prices.length - 1].time) {
      drawData({
        data: chartSeries.CGT ? [{ time, value: productValue + (chartSeries.Cash ? cashBalance : 0) }] : [],
        extradata: chartSeries.Cash ? [{ time, value: cashBalance }] : [],
        chart,
        series,
        extraseries
      })
    }
  }, [time])

  useEffect(() => {
    if (user && !user.UserSettings?.SbMigrationApproved && !window.sessionStorage.getItem('migrate')) {
      window.sessionStorage.setItem('migrate', new Date().toISOString())
      Router.push('/migrate')
    }
  }, [user])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    chartElem.current.children[0]?.remove()
    chart = null
  }, [locale])

  useEffect(() => {
    series && series.setData([])
    extraseries && extraseries.setData([])
  }, [chartSeries, chartScale, currency, authToken])

  useEffect(() => {
    if (!currency || !prices?.length || !balances?.length) {
      return
    }
    if (!chart) {
      [chart, series, extraseries] = renderChart(chartElem.current, locale, currency)
    }
    let i = 0
    const data = chartSeries.Cash && chartSeries.CGT ? prices.map(({ time, value }) => ({ time, value: value + balances[i++].value })).concat([{ time, value: productValue + cashBalance }]) : (chartSeries.CGT && prices.concat([{ time, value: productValue }]))
    const extradata = chartSeries.Cash && balances.concat([{ time, value: cashBalance }])
    drawData({
      data,
      extradata,
      chart,
      series,
      extraseries
    })
  }, [prices, chartSeries])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    let timeout
    const resize = function () {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        try {
          const rect = chartElem.current.getBoundingClientRect()
          chart.resize(rect.width, rect.height)
        } catch (e) { }
      })
    }

    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  useEffect(() => {
    const handleKeyPress = function (e) {
      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(e.target.tagName)) {
        return
      }
      if (e.key === 'b') {
        Router.push('/buy')
      } else if (e.key === 's') {
        Router.push('/sell')
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const handleTapToScroll = e => {
    const elem = e.currentTarget
    elem.scrollTo({ left: elem.scrollLeft < 1 ? elem.scrollWidth : 0, behavior: 'smooth' })
  }

  const [scrollRight, setScrollRight] = useState(false)
  const handleScrollRight = e => {
    const elem = e.currentTarget
    setScrollRight(elem.scrollLeft >= elem.scrollWidth / 4)
    if (goldInfoVisible) {
      setGoldInfoVisible(false)
    }
  }

  const handleChartSeries = (e, series) => {
    e.stopPropagation()
    const newSeries = Object.assign({}, chartSeries)
    if (!chartSeries[series] || Object.keys(newSeries).reduce((a, v) => a + !!newSeries[v], 0) > 1) {
      newSeries[series] = !chartSeries[series]
      setChartSeries(newSeries)
    }
  }

  const handleCancel = async () => {
    if (window.confirm(t('portfolio-cancelpurchase-confirm', 'Are you sure you want to cancel this purchase?'))) {
      const purchase = transactions.find(t => t.status === 'Active')
      let deposit
      if (purchase) {
        await authedFetch('/api/gb-savings/purchase/cancel/' + purchase.id, {
          method: 'POST'
        })
        deposit = transactions.find(t => t.type === 'deposit' && t.status === 'Open')
        if (deposit) {
          await authedFetch('/api/payment-intent', {
            method: 'PUT',
            body: JSON.stringify({
              PaymentIntentId: deposit.id,
              Status: 'Cancelled'
            })
          })
        }
      }
      updateTransactions({ transactions: transactions.filter(t => ![purchase?.id, deposit?.id].includes(t.id)) })
      updateHoldings(undefined)
    }
  }

  return (
    <>
      <PullToRefresh onRefresh={() => { updateHoldings(undefined); updateTransactions(undefined) }} isRefreshing={isTransactionsLoading || isPriceLoading} />
      <Header title={t('portfolio-title', 'Your Portfolio')}>
        <div className={c('holdingsOuter', { right: scrollRight, loading: isNaN(accountValue) })}>
          <div className={c('holdings')} onScroll={handleScrollRight} onClick={handleTapToScroll}>
            <div className={c('holdingsInner')}>
              <div className={c('account')}>
                <svg className={c('pie')} viewBox='0 0 32 32'>
                  <circle className={c('gold')} r='16' cx='16' cy='16' style={{ strokeDashoffset: 300 - cashPercent - (100 - cashPercent) }} />
                  <circle className={c('fiat')} r='16' cx='16' cy='16' style={{ strokeDashoffset: 300 - cashPercent }} />
                </svg>
                <div className={c('fiatvalue')}>
                  <div>
                    {!isNaN(accountValue) && currency
                      ? (
                        <output id='accountValue' className={c('value')}>
                          <NumberCount number={accountValue} options={{ style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                        </output>
                      ) : <Loading />}
                  </div>
                  {!isNaN(productWeightedCost) && !isNaN(accountValue) && productWeightedCost + cashBalance > 0 && currency &&
                    <button
                      title={t('portfolio-togglepercentdiff', 'Toggle percent price difference')}
                      className={c('percentdiff', { up: productWeightedCost <= productValue })}
                      onClick={e => { e.stopPropagation(); setPercentPriceDiff(!percentPriceDiff) }}
                    >
                      {percentPriceDiff
                        ? ((accountValue / (productWeightedCost + cashBalance)) - 1).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : (productValue - productWeightedCost).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      <Updown number={productValue - productWeightedCost} />
                    </button>}
                  <label htmlFor='accountValue' className={c('label')}>
                    {t('portfolio-accountvalue', 'Account value')} {currency && '(' + currency + ')'}
                    <br />
                    {!isNaN(productWeightedCost) && !isNaN(accountValue) && productWeightedCost + cashBalance > 0 ? t('portfolio-accountvaluechange', 'Change over weighted cost') : ' '}
                  </label>
                </div>
              </div>
              <div className={c('products')}>
                <div className={c('productamount', 'gold')}>
                  <img onClick={e => handleChartSeries(e, 'CGT')} className={c('goldbar', { off: !chartSeries.CGT })} src='/images/gold.png' alt={t('portfolio-goldbar-alt', 'Gold holding')} />
                  <div>
                    <output id='productAmount' className={c('value')}>
                      {currency && (productValue || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </output>
                    <label htmlFor='productAmount' className={c('label')}>
                      {currency && (productAmount || 0).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel + ` ${t('portfolio-productvalue', 'Gold')} (${currency})`}
                    </label>
                  </div>
                  <button className={c('infobutton')} onClick={e => { e.stopPropagation(); setGoldInfoVisible(!goldInfoVisible) }}>&#9432;</button>
                </div>
                <div className={c('productinfo', 'gold', { visible: goldInfoVisible })}>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('portfolio-gold-label', 'Gold')}:</td>
                        <td>{productHolding !== undefined && (productHolding - bonusHolding).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                      </tr>
                      <tr>
                        <td>{t('portfolio-goldreward-label', 'Gold reward')}:</td>
                        <td>{bonusHolding !== undefined && bonusHolding.toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                      </tr>
                      <tr>
                        <td>{t('portfolio-unbilledstoragefees-label', 'Unbilled storage fees')}:</td>
                        <td>{unbilledStorage !== undefined && unbilledStorage.toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits * 3, maximumFractionDigits: productUnitFractionDigits * 3 }) + productUnitShortLabel}</td>
                      </tr>
                      <tr>
                        <td>{t('portfolio-nextstoragefeebill-label', 'Next storage fee bill')}:</td>
                        <td>{account && new Date(nowDate.getFullYear(), nowDate.getDate() < createdDate.getDate() ? nowDate.getMonth() : nowDate.getMonth() + 1, createdDate.getDate(), createdDate.getHours(), createdDate.getMinutes()).toLocaleDateString(locale)}</td>
                      </tr>
                      <tr>
                        <td>0.1g {t('portfolio-minimumannualstoragefeedue-label', 'minimum annual storage fee due')}:</td>
                        <td>{account && new Date(nowDate.getTime() + ((365 * 24 * 3600000) - (nowDate.getTime() - createdDate.getTime()) % (365 * 24 * 3600000))).toLocaleDateString(locale)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={c('cashbalance')}>
                  <span onClick={e => handleChartSeries(e, 'Cash')} className={c('dollar', { off: !chartSeries.Cash })}>$</span>
                  <div>
                    <output id='cashBalance' className={c('value', { negative: cashBalance < 0 })}>
                      {currency && (cashBalance || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </output>
                    <label htmlFor='cashBalance' className={c('label')}>
                      {currency && t('portfolio-cashbalance', 'Cash') + ' (' + currency + ')'}
                    </label>
                  </div>
                  <span />
                </div>
                <div className={c('actions', { onlyone: productHolding === 0 })}>
                  <Link href='/buy'>
                    <button className={c('buy') + ' goldenshimmer'} title={t('portfolio-buy-gold-title', 'Add gold to your portfolio (B)')}>{productHolding === 0 ? t('portfolio-buy-gold-long', 'Buy gold') : t('portfolio-buy-gold-short', 'Buy')}</button>
                  </Link>
                  <Link href='/sell'>
                    <button className={c('sell')} title={t('portfolio-sell-gold-title', 'Remove gold from your portfolio (S)')}>{t('portfolio-sell-gold', 'Sell')}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={c('actions', { onlyone: productHolding === 0 })}>
            <Link href='/buy'>
              <button className={c('buy') + ' goldenshimmer'} title={t('portfolio-buy-gold-title', 'Add gold to your portfolio (B)')}>{productHolding === 0 ? t('portfolio-buy-gold-long', 'Buy gold') : t('portfolio-buy-gold-short', 'Buy')}</button>
            </Link>
            <Link href='/sell'>
              <button className={c('sell')} title={t('portfolio-sell-gold-title', 'Remove gold from your portfolio (S)')}>{t('portfolio-sell-gold', 'Sell')}</button>
            </Link>
          </div>
          {chartScale !== defaultChartScale && <div />}
          {productUnit !== defaultProductUnit && <div />}
          <div className={c('soptions')}>
            <button className={c({ active: chartScale === 'H' })} onClick={() => setChartScale('H')} title={t('prices-chartscale-hour', 'Show hour chart')}>H</button>
            <button className={c({ active: chartScale === 'D' })} onClick={() => setChartScale('D')} title={t('prices-chartscale-day', 'Show day chart')}>D</button>
            <button className={c({ active: chartScale === 'W' })} onClick={() => setChartScale('W')} title={t('prices-chartscale-week', 'Show week chart')}>W</button>
            <button className={c({ active: chartScale === 'M' })} onClick={() => setChartScale('M')} title={t('prices-chartscale-month', 'Show month chart')}>M</button>
            <button className={c({ active: chartScale === 'Y' })} onClick={() => setChartScale('Y')} title={t('prices-chartscale-year', 'Show year chart')}>Y</button>
            <hr />
            {/* <button className={c({ active: productUnit === 'ounce' })} onClick={() => setProductUnit('ounce')} title={t('prices-productunitresolution-ounce', 'Show holding in troy ounce')}>toz</button>
            <button className={c({ active: productUnit === 'gram' })} onClick={() => setProductUnit('gram')} title={t('prices-productunitresolution-gram', 'Show holding in gram')}>g</button> */}
          </div>
        </div>
      </Header>
      <div className={c('chart', { loading: pricesLoading })} ref={chartElem} />
      <div className={ct('transactions')}>
        <section>
          {(transactions?.length > 0 || isTransactionsLoading || isTransactionsError) && (
            <>
              <h3>
                <Link href='/transactions'>
                  <a>
                    {t('transactions-seeall', 'See all')}
                  </a>
                </Link>
                {t('portfolio-recenttransactions', 'Recent Transactions')}
              </h3>
            </>
          )}
          <ul>
            {isTransactionsLoading
              ? <li className={ct('empty')}><Loading /></li>
              : (!isTransactionsError
                ? (!transactions?.length
                  ? (
                    <li className={ct('empty')}>
                      <h3>{t('portfolio-notransactions-title', 'Welcome to your personal Gold Savings Account.')}</h3>
                      {t('portfolio-notransactions', 'This is your portfolio page. You\'ll find your current gold holding, cash balance and recent transactions here.')}
                      <br /><br /><br />
                      <Link href='/buy'>
                        <button className='goldenshimmer' title={t('portfolio-buy-gold-title', 'Add gold to your portfolio (B)')}>{t('portfolio-buy-gold-longer', 'Add gold to your portfolio')}</button>
                      </Link>
                      <br /><br />
                      <Link href='/sg/tour'><a>{t('portfolio-takethetour', 'Take the tour to learn more')}</a></Link>
                    </li>
                  ) : (
                    <>
                      {new Date(transactions[0].time).getTime() > new Date().getTime() - (60000 * 60 * 48) && (transactions.find(t => t.type === 'deposit' && ['Open', 'CustomerSent'].includes(t.status)) && transactions.find(t => t.status === 'Active') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-openpurchase-helper', 'You have an open purchase. Tap your deposit below and finalise it within 10 minutes to still get the quoted price. Your reserved balance will be released once the purchase times out or you cancel it.')}
                          <button onClick={handleCancel} className={'light ' + ct('button')}>{t('portfolio-openpurchase-cancel', 'Cancel')}</button>
                        </li>
                      ) : transactions.find(t => t.status === 'Active') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-expiringpurchase-helper', 'You have an open purchase. Deposit the full amount via credit card into your account within 10 minutes to still get the quoted price. Your reserved balance will be released once the purchase times out or you cancel it.')}
                          <button onClick={handleCancel} className={'light ' + ct('button')}>{t('portfolio-expiringpurchase-cancel', 'Cancel')}</button>
                        </li>
                      ) : transactions.find(t => t.type === 'deposit' && t.status === 'Open') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-opendeposit-helper', 'You have an open deposit that will expire within 2 days. Tap it to finalise your payment or cancel it.')}
                        </li>
                      ) : transactions.find(t => t.status === 'CustomerSent') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-awaitingfunds-helper', 'We are awaiting notification from our banking partner that your funds have been received. In rare cases this can take up to 24 hours.')}
                        </li>
                      ) : transactions.find(t => t.type === 'withdrawal' && t.status === 'Open') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-openwithdrawal-helper', 'We are forwarding your withdrawal request to our banking partner. Once completed the funds should appear in your registered bank account within 24 hours.')}
                        </li>
                      ) : transactions[0]?.type === 'convert' || transactions[1]?.type === 'convert' ? (
                        <li className={ct('helper')}>
                          {t('portfolio-rewardunlock-helper', 'Congratulations! You have unlocked your gold reward and it is now available for sale.')}
                        </li>
                      ) : transactions[0]?.type === 'grant' || transactions[1]?.type === 'grant' ? (
                        <li className={ct('helper')}>
                          {t('portfolio-rewardgrant-helper', 'You received a gold reward! Well done. It becomes available for sale once you successfully add a minimum of 5 grams of gold to your portfolio.')}
                        </li>
                      ) : null)}
                      {transactions.map(ta => (
                        <li key={ta.time + ta.sku + ta.id}>
                          <Transaction
                            id={ta.id}
                            type={ta.type}
                            cashAmount={ta.value}
                            currency={ta.currency}
                            product={ta.sku}
                            productAmount={ta.amount}
                            productBalance={ta.balance}
                            productUnit={ta.unit}
                            status={ta.status}
                            time={new Date(ta.time)}
                          />
                        </li>
                      ))}
                    </>
                  )
                ) : (
                  <li className={ct('empty')}>
                    {t('portfolio-transactions-error', 'We\'re having trouble loading your portfolio. We\'ll keep trying...')}
                  </li>
                ))}
          </ul>
        </section>
      </div>
      <Cards />
      <Footer />
    </>
  )
}

Portfolio.requiresAuth = true
Portfolio.getLayout = getLayout
