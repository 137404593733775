import style from './Transaction.module.css'

import classNames from 'classnames/bind'
import resolveProductUnit from '../helpers/resolveProductUnit'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Link from 'next/link'
import TimeAgo from './TimeAgo'
import useTimer from '../hooks/useTimer'

const purchaseTimeout = 10 * 60000

export default function Transaction ({ id, type, product, productAmount, productUnit, cashAmount, currency, status, time }) {
  const [t, i18n] = useTranslation('main')
  const locale = i18n.language
  const c = classNames.bind(style)
  const { remaining, remainingString, startTimer } = useTimer()

  useEffect(() => {
    if (status === 'Active' && type === 'purchase') {
      startTimer(new Date(time).getTime() + purchaseTimeout)
    }
  }, [])

  const { productUnitLabel, productUnitFractionDigits } = resolveProductUnit(productUnit, productAmount)

  let title
  switch (product) {
    case 'CGT':
      title = t('transaction-sku-cgt', 'Gold') + ' '
      break
    case 'CGTBonus':
      title = t('transaction-sku-cgtbonus', 'Gold reward') + ' '
      break
    default:
      title = t('transaction-cash', 'Cash') + ' '
  }

  switch (type) {
    case 'balance':
      title += t('transactions-type-balance', 'balance')
      break
    case 'sale':
      title += t('transactions-type-sale', 'sale')
      break
    case 'storage':
      title += t('transactions-type-storage', 'storage fee')
      break
    case 'purchase':
      title += t('transactions-type-purchase', 'purchase')
      break
    case 'grant':
      title += t('transactions-type-earned', 'earned')
      break
    case 'convert':
      title += t('transactions-type-unlocked', 'unlocked')
      break
    case 'debit':
      title += t('transactions-type-debit', 'debit')
      break
    case 'credit':
      title += t('transactions-type-credit', 'credit')
      break
    case 'deposit':
      title += t('transactions-type-deposit', 'deposit')
      break
    case 'withdrawal':
      title += t('transactions-type-withdrawal', 'withdrawal')
  }

  const open = ['Open', 'CustomerSent'].includes(status)
  if (id && !open && productAmount !== 0) {
    title += ` #${id}`
  }

  if (status === 'Active' && remaining < 0) {
    status = 'Cancelled'
  }

  let statusLabel
  switch (status) {
    case 'Open':
      statusLabel = type === 'deposit' ? t('transactions-status-paymentrequired', 'Payment required') : t('transactions-status-inprogress', 'In progress')
      break
    case 'Completed':
      statusLabel = t('transactions-status-completed', 'Completed')
      break
    case 'CustomerSent':
      statusLabel = t('transactions-status-awaitingfunds', 'Awaiting funds')
      break
    case 'Active':
      statusLabel = t('transactions-status-onhold', 'On hold') + ' ' + remainingString
      break
    case 'Cancelled':
      statusLabel = t('transactions-status-cancelled', 'Cancelled')
      break
    case 'Reserved':
      statusLabel = t('transactions-status-reserved', 'Reserved')
      break
  }

  const lastTwelveMinutes = new Date(time).getTime() > new Date().getTime() - (60000 * 12)

  const tx = (
    <>
      <h4>{title}</h4>
      <div>
        {(status !== 'Completed' || lastTwelveMinutes) && <span className={c('status', status)}>{statusLabel}</span>}
        {time && <TimeAgo className={c('datetime')} date={time} options={{ month: 'long' }} />}
      </div>
      {productUnit !== currency
        ? <span className={c('productamount', productAmount >= 0 ? 'positive' : 'negative')}>{(productAmount || 0).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits })} {productUnitLabel}</span>
        : <span className={c('productamount', productAmount >= 0 ? 'positive' : 'negative')}>{(productAmount || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
      {productUnit !== currency && ['purchase', 'sale'].includes(type) && <span className={c('fiatamount')}>{(cashAmount || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
    </>
  )

  return open && type === 'deposit' ? (
    <Link href={`/payment/${id}`}>
      <a className={c('transaction')}>{tx}</a>
    </Link>
  ) : (
    <span className={c('transaction')}>{tx}</span>
  )
}
