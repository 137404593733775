import useSWR from 'swr'
import useAuth from './useAuth.js'
import { chartScales, defaultChartScale } from '../helpers/renderChart'

export function pricesFilter (prices, scale) {
  const now = new Date()
  const nowSeconds = now.getTime() / 1000 - now.getTimezoneOffset() * 60
  const scaleSeconds = (chartScales.find(c => c.name === scale) || chartScales.find(c => c.name === defaultChartScale)).seconds
  // .log(scaleSeconds, prices.map(p => nowSeconds - p.time))
  return prices.filter(p => nowSeconds - p.time < scaleSeconds)
}

export function priceMapper (item, lookupFunc) {
  return item.error ? {} : {
    time: new Date(item.Time).getTime() / 1000 - new Date().getTimezoneOffset() * 60,
    value: lookupFunc(item).Valuation
  }
}

export function getHoldingEndPoint (chartScale, date) {
  const { interval, datapoints, steps } = chartScales.find(c => c.name === chartScale) || chartScales.find(c => c.name === defaultChartScale)
  date = date ? `&toDate=${new Date(date).toISOString()}` : ''
  return `/api/gb-savings/holdings?qtyIntervals=${datapoints + 1}&interval=${interval}&intervalSize=${steps}${date}`
}

export function useHoldingsHistory (chartScale) {
  const { isAuthed, authToken } = useAuth()
  const { data, error, isValidating } = useSWR(isAuthed ? [getHoldingEndPoint(chartScale), authToken] : null, {
    refreshInterval: 60000
  })

  const currency = data?.[0]?.Currency

  return {
    balances: {
      Cash: (!data?.length ? [] : data)?.map(m => priceMapper(m, m => m.Balances[currency] || {})).reverse()
    },
    holdings: {
      CGT: (!data?.length ? [] : data)?.map(m => priceMapper(m, m => m.Holdings ? {
        Amount: (m.Holdings.CGT?.Amount || 0) + (m.Holdings.CGTBonus?.Amount || 0),
        Bonus: (m.Holdings.CGTBonus?.Amount || 0),
        Valuation: (m.Holdings.CGT?.Valuation || 0) + (m.Holdings.CGTBonus?.Valuation || 0),
        DailyStorage: (m.Holdings.CGT?.DailyStorage || 0) + (m.Holdings.CGTBonus?.DailyStorage || 0),
        AccumulatedStorage: (m.Holdings.CGT?.AccumulatedStorage || 0) + (m.Holdings.CGTBonus?.AccumulatedStorage || 0),
        WeightedCost: (m.Holdings.CGT?.WeightedCost || 0) + (m.Holdings.CGTBonus?.WeightedCost || 0)
      } : {})).reverse()
    },
    currency: currency,
    isLoading: isValidating,
    isError: error
  }
}

export function useLiveHoldings () {
  const { isAuthed, authToken } = useAuth()
  const { data, error, mutate: updateHoldings } = useSWR(isAuthed ? ['/api/gb-savings/holdings?qtyIntervals=1&interval=minute&intervalSize=1', authToken] : null, {
    refreshInterval: 10000
  })

  const currency = data?.[0]?.Currency

  return {
    time: new Date(data?.[0]?.Time || new Date()).getTime() / 1000 - new Date().getTimezoneOffset() * 60,
    balances: data?.[0]?.Balances
      ? (data[0].Balances?.[currency]
        ? { Cash: data[0].Balances?.[currency] }
        : { Cash: { Amount: 0, Valuation: 0 } })
      : { Cash: {} },
    holdings: data?.[0]?.Holdings
      ? (Object.keys(data[0].Holdings).length
        ? {
          CGT: {
            Amount: (data[0].Holdings.CGT?.Amount || 0) + (data[0].Holdings.CGTBonus?.Amount || 0),
            Bonus: (data[0].Holdings.CGTBonus?.Amount || 0),
            Valuation: (data[0].Holdings.CGT?.Valuation || 0) + (data[0].Holdings.CGTBonus?.Valuation || 0),
            WeightedCost: (data[0].Holdings.CGT?.WeightedCost || 0) + (data[0].Holdings.CGTBonus?.WeightedCost || 0),
            AccumulatedStorage: (data[0].Holdings.CGT?.AccumulatedStorage || 0) + (data[0].Holdings.CGTBonus?.AccumulatedStorage || 0),
            DailyStorage: (data[0].Holdings.CGT?.DailyStorage || 0) + (data[0].Holdings.CGTBonus?.DailyStorage || 0)
          }
        }
        : { CGT: { Amount: 0, Bonus: 0, Valuation: 0, DailyStorage: 0, WeightedCost: 0 } })
      : { CGT: {} },
    currency,
    isLoading: !data && !error,
    isError: error,
    updateHoldings
  }
}
